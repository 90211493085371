import { Row, Col, Card, Button, Dropdown, Table, Badge } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../layout/default';
// import {

//   Select
// } from '../components';
import Block from '../components/Block/Block';
import DataTable from '../components/DataTable/DataTable';
import { Colors } from '../utilities/index';
import hexRGB from '../utilities/hexRGB';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import loadingicon from '../assets/Rollingicon.svg';

function MessageToUser() {
  const serverApiUrl = process.env.REACT_APP_API_URL;

  const [user_ids, setUser_ids] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [allusers, setAllusers] = useState([]);
  const [allUserMessages, setAllUserMessages] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [fromDate, setFromDate] = useState(''); // Initialize with an empty string or a specific date string
  const [toDate, setToDate] = useState('');
  const [UsersFullData, setUsersFullData] = useState([]);
  const [page, setpage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const fetchmessagelist = async (page, rowsPerPage) => {
    try {
      const response = await axios.get(`${serverApiUrl}/api/Admin/allUserMessages?page=${page}&pageSize=${rowsPerPage}`);
      // console.log(response.data);
      setAllUserMessages(response?.data?.rows);
      setUsersFullData(response?.data);
      // console.log(allusers)
      setTitle(response.data[0]['full_name'])
    } catch (error) {
      console.error('Error fetching last ten transactions:', error);
    }
  };
  // const fetchuserslist = async () => {
  //   try {
  //     const response = await axios.get(`${serverApiUrl}/api/Admin/allusers`);
  //     // console.log(response.data);
  //     setAllusers(response?.data);
  //     const userdata = response.data
  //     const userdropdownOptions = userdata.map(user => ({
  //       value: user?.user_id.toString(), // ensuring value is a string, change to user.user_id if number is needed
  //       label: user?.full_name
  //     }));
  //     const ddropdownOptions = [{ value: 'all', label: 'All' }, ...userdropdownOptions];
  //     setDropdownOptions(ddropdownOptions)
  //     // console.log(ddropdownOptions)
  //     // console.log(allusers)
  //   } catch (error) {
  //     console.error('Error fetching last ten transactions:', error);
  //   }
  // };
  useEffect(() => {
    const fetchuserlist = async () => {
      try {
        const response = await axios.get(`${serverApiUrl}/api/Admin/allusers`);
        // console.log(response.data);
        setAllusers(response.data);
        const userdata = response.data
        const userdropdownOptions = userdata.map(user => ({
          value: user?.user_id.toString(), // ensuring value is a string, change to user.user_id if number is needed
          label: user?.full_name
        }));
        const ddropdownOptions = [{ value: 'all', label: 'All' }, ...userdropdownOptions];
        setDropdownOptions(ddropdownOptions)
        // console.log(ddropdownOptions)
        // console.log(allusers)
      } catch (error) {
        console.error('Error fetching last ten transactions:', error);
      }
    };



    fetchuserlist();
    // fetchmessagelist();
  }, []);
  useEffect(() => {
    fetchmessagelist(page, rowsPerPage);
  }, [page, rowsPerPage]);
  const handleFromDateChange = (event) => {
    const selectedFromDate = event.target.value;
    setFromDate(event.target.value);
    // console.log(selectedFromDate)
    // console.log(toDate)
    if (selectedFromDate && toDate && toDate < selectedFromDate) {
      setToDate('');
      toast.error('To date is higher than From date');
    }
  };
  const handleToDateChange = (event) => {
    setToDate(event.target.value);
    const selectedToDate = event.target.value;
    // console.log(selectedToDate)
    // console.log(fromDate)
    if (fromDate && selectedToDate && selectedToDate < fromDate) {
      setToDate('');
      toast.error('To date is higher than From date');
    }
  };
  const get_users_by_dates_clear = async () => {
    setFromDate('');
    setToDate('');
    setDropdownOptions([]);
    try {
      const response = await axios.get(`${serverApiUrl}/api/Admin/allusers`);
      // console.log(response.data);
      setAllusers(response.data);
      const userdata = response.data
      const userdropdownOptions = userdata.map(user => ({
        value: user?.user_id?.toString(), // ensuring value is a string, change to user.user_id if number is needed
        label: user?.full_name
      }));
      const ddropdownOptions = [{ value: 'all', label: 'All' }, ...userdropdownOptions];
      setDropdownOptions(ddropdownOptions)
      // console.log(allusers)
    } catch (error) {
      console.error('Error fetching last ten transactions:', error);
    }
  }
  const get_users_by_dates = async () => {

    try {
      setDropdownOptions([]);
      const response = await axios.get(`${serverApiUrl}/api/Admin/allusers?from_date=${fromDate}&to_date=${toDate}`);
      setAllusers(response.data);
      const userdata = response.data
      const userdropdownOptions = userdata.map(user => ({
        value: user.user_id.toString(), // ensuring value is a string, change to user.user_id if number is needed
        label: user.full_name
      }));
      if (userdropdownOptions.length > 0) {
        const ddropdownOptions = [{ value: 'all', label: 'All' }, ...userdropdownOptions];
        setDropdownOptions(ddropdownOptions)
      } else {
        const ddropdownOptions = userdropdownOptions;
        setDropdownOptions(ddropdownOptions)
      }



    } catch (error) {
      console.error('Error fetching data:', error);
    }


  }
  const [restaurantsData, setRestaurantsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const userColumns = [
    {
      name: "Id",
      selector: (row) => row.message_id,
      cell: (row) => <span>{row.message_id}</span>,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      cell: (row) => <span>{row.title}</span>,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      cell: (row) => <span>{row.message}</span>,
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Total Count",
      selector: (row) => row?.successCount + row?.failureCount,
      cell: (row) => <span>{row?.successCount + row?.failureCount}</span>,
      sortable: true,
      // minWidth: "150px",
    },
    {
      name: "Success Count",
      selector: (row) => row.successCount,
      cell: (row) => <span>{row.successCount}</span>,
      sortable: true,
      // minWidth: "150px",
    },
    {
      name: "Failure Count",
      selector: (row) => row.failureCount,
      cell: (row) => <span>{row.failureCount}</span>,
      sortable: true,
      // minWidth: "150px",
    },
    {
      name: "Send Message ON Date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span>{new Date(row.createdAt).toLocaleString()}</span>

      ),
      sortable: true,
      minWidth: "200px",
    },
  ]

  const handleSelectChange = (e) => {
    // console.log(e)
    const selectedOptions = Array.from(e.value, option => option.value);
    setUser_ids(selectedOptions);
  };
  const handleChange = option => {
    setSelectedOption(option);
  };
  const handleSubmit = async () => {
    try {
      //  console.log(selectedOption)
      if (title === '' || title === 'null' || title === 'undefined') {
        return toast.error("Please enter title");
      }
      if (message === '' || message === 'null' || message === 'undefined') {
        return toast.error("Please enter message");
      }
      if (selectedOption != null && selectedOption.length > 0) {

        const data = {
          user_ids: selectedOption,
          title: title,
          message: message,
          from_date: fromDate,
          to_date: toDate
        };
        // console.log(data)
        setLoading(true);
        const response = await axios.post(`${serverApiUrl}/api/Admin/addMessages`, data);
        setFromDate('');
        setToDate('');
        setTitle('')
        setMessage('')
        setSelectedOption(null)
        // console.log(response.data.message)
        toast.success(response.data.message);
        fetchmessagelist();
        // fetchuserslist();

      } else {
        toast.error("Please select atleast one User");
      }

      // console.log(response)
    } catch (error) {
      console.error("Error submiting data:", error);
      // console.log(error.response.data.error);
      return toast.error(error.response.data.error);
    } finally {
      setLoading(false);
    }

  }
  const buttonStyle = {
    marginTop: '33px',
    marginLeft: '20px',
  };
  return (
    <Layout title="Users List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">User Messages List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item"><Link to="/home-ecommerce">Home</Link></li>
                <li className="breadcrumb-item"><Link to="">Send Message to Users</Link></li>
              </ol>
            </nav>

          </Block.HeadContent>

        </Block.HeadBetween>
        <Col xxl="12">
          <Row className="g-0 col-sep col-sep-md">
            <Col md="3">
              <Form.Control
                id="couponname"
                type="date"
                placeholder="Name"
                required
                value={fromDate}
                onChange={(e) => handleFromDateChange(e)}

              />

            </Col>
            <Col md="3">
              <Form.Control
                id="toDate"
                type="date"
                placeholder="Name"
                required
                value={toDate}
                onChange={(e) => handleToDateChange(e)}

              />

            </Col>

            <Col md="2">
              <Button variant="primary" onClick={get_users_by_dates}>Submit</Button>
            </Col>
            <Col md="2">
              <Button variant="warning" onClick={get_users_by_dates_clear}>Clear</Button>
            </Col>
          </Row>
        </Col>
      </Block.Head>
      <Col xxl="12">

        <Row className="g-2 m-1 col-sep col-sep-md">

          <Col md="3">

            <Form.Label htmlFor="fullname">Select User *</Form.Label>
            <Select
              isMulti
              value={selectedOption}
              onChange={handleChange}
              options={dropdownOptions}
            />
            {/* <Select removeItemButton
                                            value={user_ids}
                                            required
                                            multiple
                                            onChange={handleSelectChange}
                                            options={allusers}
                                        >
                                            <option key="1000000" value="">Select a type</option>
                                            {allusers.map(user => (
                                        <option key={user.user_id} value={user.user_id}>{user.full_name}</option>
                                        ))}
                        </Select> */}
          </Col>
          <Col md="3">
            <Form.Label htmlFor="title">Enter Title *</Form.Label>
            <Form.Control
              id="title"
              type="text"
              placeholder="Name"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}


            />

          </Col>

          <Col md="3">
            <Form.Label htmlFor="title">Enter Message *</Form.Label>
            <Form.Control
              id="message"
              type="text"
              placeholder="Enter Message"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}


            />

          </Col>

          <Col md="2">

            <Button style={buttonStyle} variant="primary" onClick={handleSubmit} disabled={loading}>{loading ? <img src={loadingicon} alt='loading' width={'50px'} height={'30px'} /> : 'Submit'}</Button>
          </Col>

        </Row>

      </Col>
      <Block>
        <Card>
          <DataTable tableClassName="data-table-head-light table-responsive" page={page} setpage={setpage} rowsPerPages={rowsPerPage} setRowsPerPage={setRowsPerPage} fullData={UsersFullData} data={allUserMessages} columns={userColumns} />
        </Card>
      </Block>

    </Layout>
  )
}

export default MessageToUser;
